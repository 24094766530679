<template>
  <b-container fluid>
      <b-row v-if="!loader">
        <b-col lg="12" sm="12">
           <template>
              <b-row>
                <b-col>
                  <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id="seedOrgId">
                      {{ $t('seedsSeeds.applicationAllocationDetailsList') }}
                  </list-report-head>
                </b-col>
              </b-row>
              </template>
              <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                            </b-col>
                            <b-col lg="3" sm="12">
                              <p class="text-dark">{{$t('seedsSeeds.applicantType')}} : </p>
                            </b-col>
                            <b-col lg="3" sm="12">
                                  <p class="text-dark capitalize" v-if="data.applicant_type_id == 1">{{ $t('seedsSeeds.company') }}</p>
                                  <p class="text-dark capitalize" v-else-if="data.applicant_type_id == 2">{{ $t('seedsSeeds.farmer') }}</p>
                                  <p class="text-dark capitalize" v-else>{{ $t('seedsSeeds.dealer') }}</p>
                            </b-col>
                            <b-col lg="12" sm="12">
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th>{{ $t('seedsConfig.variety') }}</b-th>
                                        <b-th>{{ $t('globalTrans.quantity') }}</b-th>
                                    </b-tr>
                                    <b-tr v-for="(res,index) in  data.sd_allocation_app_details" :key="index">
                                        <b-td>{{ $n(index+1) }}</b-td>
                                        <b-td>{{ ($i18n.locale==='bn') ? res.variety.variety_name_bn  :  res.variety.variety_name }}</b-td>
                                        <b-td>{{res.quantity}}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>

import { core } from '@/config/pluginInit'
import { dateFormat } from '@/Utils/fliter'
import ListReportHead from '@/components/custom/ListReportHeadSeed.vue'
import { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList } from '../../api/routes'
import ExportPdf from './export_pdf_details'

export default {
  components: {
    ListReportHead
  },
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getApplicationInfoData()
       this.data = tmp
    }
    this.seedOrgId = 1
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.seedOrgId = this.$store.state.Auth.authUser.org_id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
     saveBtnName: this.$t('globalTrans.view'),
     seedOrgId: 0,
     loading: false,
      data: {
        id: '',
        org_id: 0,
        fiscal_year_id: 0,
        production_season_id: 0,
        production_source_id: 0,
        carryovers_id: 0,
        program_type_id: 0,
        spc_office_id: 0,
        seed_class_id: 0,
        crop_type_id: 0,
        crop_name_id: 0,
        measurement_unit_id: 0,
        stock_date: null,
        sd_allocation_app_details: []
      },
      cropNameList: [],
      loader: false,
      search: {
        crop_name_id: 0
      },
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
      columns () {
          if (this.$i18n.locale === 'bn') {
              return [
                  { key: this.$t('org_pro.organization'), val: this.data.org_name_bn, key1: this.$t('seedsConfig.seedClass'), val1: this.data.seed_class_bn },
                  { key: this.$t('seedsConfig.measurementUnit'), val: this.data.measurementUnit_name_bn, key1: this.$t('seedsConfig.cropType'), val1: this.data.crop_type_name_bn },
                  { key: this.$t('seedsConfig.cropName'), val: this.data.crop_name_name_bn, key1: this.$t('seedsSeeds.application_date'), val1: dateFormat(this.data.application_date) },
                  { key: this.$t('seedsConfig.productionSeason'), val: (this.data.production_season_name_bn) }
              ]
          } else {
              return [
                  { key: this.$t('org_pro.organization'), val: this.data.org_name, key1: this.$t('seedsConfig.seedClass'), val1: this.data.seed_class },
                  { key: this.$t('seedsConfig.measurementUnit'), val: this.data.measurementUnit_name, key1: this.$t('seedsConfig.cropType'), val1: this.data.crop_type_name },
                  { key: this.$t('seedsConfig.cropName'), val: this.data.crop_name_name, key1: this.$t('seedsSeeds.application_date'), val1: (this.data.application_date) },
                  { key: this.$t('seedsConfig.productionSeason'), val: (this.data.production_season_name) }
              ]
          }
      }
  },
  methods: {
    getApplicationInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getCropNameList (croptypeID) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (croptypeID) {
        return cropNameList.filter(cropName => cropName.crop_type_id === croptypeID)
      }
      return cropNameList
    },
    pdfExport () {
      const reportTitle = this.$t('seedsSeeds.applicationAllocationDetailsList')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/seeds/config/report-heading/detail', this.seedOrgId, reportTitle, this.data, this)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
